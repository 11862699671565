$footer-background: #000; // Black
$link-color: white; // Yellow
$footer-text-color: #fdd835; // Yellow

.footerContainer {
  background-color: $footer-background;
  color: $footer-text-color;
  text-align: center;
  padding: 1rem;
  position: relative;
  bottom: 0;
  width: 100%;

  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}


.hiddenInput {
  display: none;
}

$background: #fdd835; // Bright yellow
$text-color: #000; // Black
$border-color: #000; // Black

.fileUploaderContainer {
  background-color: $background;
  color: $text-color;
  font-family: 'Arial', sans-serif;
  padding: 4rem 2rem;
  border: 2px solid $border-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 20vh;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.fileInputLabel {
  padding: 1rem 2rem;
  background-color: $text-color;
  color: $background;
  border: none;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-0.25rem);
  }
}

.fileNameDisplay {
  font-family: 'Courier New', monospace;
  font-weight: 600;
}

.uploadButton {
  padding: 1rem 2rem;
  background-color: transparent;
  color: $text-color;
  border: 2px solid $text-color;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  
  &:hover {
    background-color: $text-color;
    color: $background;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background-color: inherit;
      color: inherit; 
      cursor: default;
    }
  }
}

.explainerText {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.5;
  max-width: 600px;
}

$text-color: #000;
$link-color: white;

.headerContainer {
  background-color: transparent;

  a {
    color: $link-color;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
